<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <h3 class="title carousel-item-title mb-6">Данные контрагента</h3>

      <div>
        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Страна *"
              v-model="formData.contractors_data.country.value"
              inputType="select"
              :disabled="status"
              :options="[
                { value: 'Россия', name: 'Россия' },
                { value: 'Украина', name: 'Украина' },
                { value: 'Польша', name: 'Польша' },
                { value: 'Италия', name: 'Италия' },
                { value: 'Франция', name: 'Франция' },
                { value: 'Беларусь', name: 'Беларусь' },
                { value: 'Турция', name: 'Турция' },
                { value: 'Германия', name: 'Германия' },
                { value: 'Чехия', name: 'Чехия' },
                { value: 'Финляндия', name: 'Финляндия' }
              ]"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.country) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Организационно-правовая форма *"
              inputType="select"
              :options="[
                { value: 1, name: 'Юридическое лицо' },
                { value: 2, name: 'ИП' }
              ]"
              v-model="formData.contractors_data.type_id.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.type_id) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Наименование *"
              v-model="formData.contractors_data.name_full.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.name_full) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="ИНН *"
              inputType="number"
              :maxLength="
                formData.contractors_data.type_id.value == 2 ? 12 : 10
              "
              v-model="formData.contractors_data.inn.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.inn) }}
            </p>
          </div>
        </div>

        <div
          class="columns"
          v-if="formData.contractors_data.type_id.value == '1'"
        >
          <div class="column pb-0">
            <tnd-input
              inputLabel="КПП *"
              inputType="number"
              :maxLength="9"
              v-model="formData.contractors_data.kpp.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.kpp) }}
            </p>
          </div>

          <div class="column pb-0">
            <tnd-input
              inputLabel="КОД ОКПО *"
              inputType="number"
              :maxLength="8"
              v-model="formData.contractors_data.okpo.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.okpo) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="ОГРН/ОРГНИП *"
              inputType="number"
              :disabled="status"
              :maxLength="
                formData.contractors_data.type_id.value == 2 ? 15 : 13
              "
              v-model="formData.contractors_data.ogrn.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.ogrn) }}
            </p>
          </div>

          <div class="column pb-0">
            <tnd-input
              inputLabel="Дата регистрации компании *"
              inputType="datepicker"
              datepickerType="date"
              disabedFuture="true"
              datepickerFormat="YYYY-MM-DD"
              :disabled="status"
              v-model="
                formData.contractors_data.company_date_registration.value
              "
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(
                  formData.contractors_data.company_date_registration
                )
              }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Плательщик НДС *"
              v-model="formData.contractors_data.payer_nds.value"
              inputType="select"
              :options="[
                { value: '1', name: 'Нет' },
                { value: '2', name: 'Да' }
              ]"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.payer_nds) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Адрес места регистрации *"
              v-model="formData.contractors_data.legal_address.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.legal_address) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Является ли ваш адрес «массовым», или нет *"
              v-model="formData.contractors_data.mass_address.value"
              inputType="select"
              :disabled="status"
              :options="[
                { value: '1', name: 'Нет' },
                { value: '2', name: 'Да' }
              ]"
            >
              <!-- <template #label>
                <span class="mr-2">Является ли ваш адрес «массовым», или нет *</span>
                <b-tooltip class="is-absolute" type="is-light" multilined>
                  <ExclamationInCircleIcon :size="15" />
                  <template v-slot:content>
                    Адрес считается массовым, если по нему зарегистрировано
                    10&nbsp;и&nbsp;более субъектов предпринимательской
                    деятельности
                  </template>
                </b-tooltip>
              </template> -->
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.mass_address) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Фактический адрес *"
              v-model="formData.contractors_data.actual_address.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.actual_address) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="ТЕЛЕФОН *"
              v-model="formData.contractors_data.organization_phone.value"
              inputType="phone"
              :disabled="status"
              :maxLength="13"
            />

            <p class="has-text-danger">
              {{
                getErrorMessage(formData.contractors_data.organization_phone)
              }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="E-mail *"
              type="email"
              :disabled="status"
              v-model="formData.contractors_data.email.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.email) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="ВЕБ-САЙТ"
              :disabled="status"
              v-model="formData.contractors_data.www.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.www) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Наименование банка *"
              :disabled="status"
              v-model="formData.contractors_data.bank_name.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.bank_name) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Город местонахождения банка *"
              :disabled="status"
              v-model="formData.contractors_data.bank_location.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.bank_location) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="БИК *"
              v-model="formData.contractors_data.bik.value"
              inputType="number"
              :disabled="status"
              :maxLength="9"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.bik) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Расчетный счет *"
              v-model="formData.contractors_data.bank_account.value"
              inputType="number"
              :disabled="status"
              :maxLength="20"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.bank_account) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Корреспондентский счет *"
              v-model="formData.contractors_data.correspondent_account.value"
              inputType="number"
              :disabled="status"
              :maxLength="20"
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(formData.contractors_data.correspondent_account)
              }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0 tnd-input-container">
            <label
              ><div class="tnd-input-label">
                Выручка за последние 3 года *
              </div>
            </label
            >
          </div>
        </div>
        <div
          class="columns"
          v-for="(item, index) in formData.contractors_data.turnover_last_years"
          :key="index"
        >
          <div class="column pb-0">
            <tnd-input
              inputType="datepicker"
              datepickerType="year"
              datepickerFormat="yyyy"
              inputLabel="Год"
              :disabled="status"
              v-model="item.year"
            />
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="в тыс. руб., без НДС"
              inputType="price"
              :value="item.income"
              :disabled="status"
              @input="validateTurnover($event, index)"
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(item)
              }}
            </p>
          </div>
        </div>

        <div
          class="columns"
          v-if="
            formData.contractors_data.turnover_last_years &&
            formData.contractors_data.turnover_last_years.length < 3
          "
        >
          <div class="column pb-0 tnd-input-container calendar-add-row" :disabled="status">
            <a @click="addRowToLastIncomeData">
              <calendar-add />
              <span>Добавить еще одно поле</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="p-6 shadow-d mt-6">
      <h3 class="title carousel-item-title carousel-item-title-small mb-5">
        Руководители
      </h3>

      <div>
        <div
          class="columns"
          v-if="formData.contractors_data.type_id.value == '1'"
        >
          <div class="column pb-0">
            <tnd-input
              inputLabel="ФИО учредителя"
              :disabled="status"
              v-model="formData.contractors_data.charter_member.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.charter_member) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="ФИО руководителя"
              v-model="formData.contractors_data.ceo.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.ceo) }}
            </p>
          </div>
        </div>


        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Почта учредителя"
              :disabled="status"
              type="email"
              v-model="formData.contractors_data.charter_member_email.value"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.charter_member_email) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="Почта руководителя"
              type="email"
              v-model="formData.contractors_data.ceo_email.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.ceo_email) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="true"
              v-model="formData.contractors_data.founder_mass.value"
              inputType="select"
              :disabled="status"
              :options="[
                { value: '1', name: 'Нет' },
                { value: '2', name: 'Да' }
              ]"
            >
              <template #label>
                <span class="mr-2"
                  >Является ли учредитель «массовым» учредителем</span
                >
                <b-tooltip class="is-absolute" type="is-light" multilined>
                  <ExclamationInCircleIcon :size="15" />
                  <template v-slot:content>
                    Учредитель признается массовым тогда, когда он принимает
                    участие в деятельности более 10-ти компаний
                  </template>
                </b-tooltip>
              </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.founder_mass) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="true"
              v-model="formData.contractors_data.leader_mass.value"
              inputType="select"
              :disabled="status"
              :options="[
                { value: '1', name: 'Нет' },
                { value: '2', name: 'Да' }
              ]"
            >
              <template #label>
                <span class="mr-2"
                  >Является ли руководитель «массовым» руководителем</span
                >
                <b-tooltip class="is-absolute" type="is-light" multilined>
                  <ExclamationInCircleIcon :size="15" />
                  <template v-slot:content>
                    Массовым руководитель признается тогда, когда он управляет
                    более чем 5-ю фирмами
                  </template>
                </b-tooltip>
              </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.leader_mass) }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="p-6 shadow-d mt-6">
      <h3 class="title carousel-item-title carousel-item-title-small mb-5">
        Дополнительная информация
      </h3>

      <div>
        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Разрешительная документация"
              v-model="formData.contractors_data.solving_documentation.value"
              inputType="select"
              :disabled="status"
              :options="[
                { value: '1', name: 'Система менеджмента качества ISO' },
                { value: '2', name: 'Другая СМК или сертификаты на продукцию' },
                {
                  value: '3',
                  name: 'Продукция и деятельность компании не требует сертификации'
                }
              ]"
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(formData.contractors_data.solving_documentation)
              }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="ОРГАНИЗАЦИЯ ПРОИЗВОДИТЕЛЬ ИЛИ ПОСТАВЩИК?"
              v-model="formData.contractors_data.producer_or_supplier.value"
              inputType="select"
              :disabled="status"
              :options="[
                {
                  value: '1',
                  name: 'Производитель/подрядчик собственные силы'
                },
                {
                  value: '2',
                  name: 'Поставщик/подрядчик с привлечением сторонних производителей работ'
                }
              ]"
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(formData.contractors_data.producer_or_supplier)
              }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="УСЛОВИЯ ДОСТАВКИ ТОВАРОВ"
              v-model="formData.contractors_data.delivery_terms.value"
              inputType="select"
              :disabled="status"
              :options="[
                {
                  value: '1',
                  name: 'Арендованный автотранспорт/Возможность отправки ЖД'
                },
                {
                  value: '2',
                  name: 'Собственный автопарк/Возможность отправки ЖД'
                }
              ]"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.delivery_terms) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputType="number"
              inputLabel="КОЛИЧЕСТВО ОФИЦИАЛЬНО ТРУДОУСТРОЕННЫХ СОТРУДНИКОВ"
              v-model="formData.contractors_data.employees_count.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.employees_count) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="Специализация"
              v-model="formData.contractors_data.company_specialization.value"
              inputType="select"
              :disabled="status"
              :options="[
                {
                  value: '1',
                  name: 'Более 10 видов деятельности согласно выписки ЕГРЮЛ'
                },
                {
                  value: '2',
                  name: '10 или менее видов деятельности согласно выписки ЕГРЮЛ'
                }
              ]"
            />
            <p class="has-text-danger">
              {{
                getErrorMessage(
                  formData.contractors_data.company_specialization
                )
              }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="true"
              v-model="formData.contractors_data.comment.value"
              :disabled="status"
            >
              <template #label>
                <span class="mr-2">Дополнительная информация</span>
                <b-tooltip class="is-absolute" type="is-light">
                  <ExclamationInCircleIcon :size="15" />
                  <template v-slot:content>
                    Направление деяльности компании, отрасль
                  </template>
                </b-tooltip>
              </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.contractors_data.comment) }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="has-text-centered mt-6">
      <button
          :disabled="status"
        @click="save('contractors_data')"
        class="button is-normal main-button purple"
      >
        <span>СОХРАНИТЬ</span>
      </button>
    </div>
  </b-carousel-item>
</template>

<script>
import TndInput from '@/components/common/TndInput.vue'
import CalendarAdd from '../components/icons/CalendarAdd.vue'
import ExclamationInCircleIcon from '../components/icons/ExclamationInCircleIcon.vue'
import validate from '../services/validate.service.js'

const disableStatus = 18
export default {
  props: ['getErrorMessage', 'save'],
  components: {
    TndInput,
    CalendarAdd,
    ExclamationInCircleIcon
  },
  name: 'ProfileContragent',
  computed: {
    formData() {
      return this.$store.getters['profile/profileData']
    },
    status() {
      return this.$store.getters['profile/orgStatus'] === disableStatus
    }
  },
  created() {
    for (let i in this.formData.contractors_data) {
      this.$watch(
        () => this.formData.contractors_data[i].value,
        () => {
          validate(this.formData.contractors_data[i], this.formData.contractors_data.type_id.value )
        }
      )
    }
  },
  methods: {
    validateTurnover(income, index){
      this.formData.contractors_data.turnover_last_years[index].income = income
      validate(this.formData.contractors_data.turnover_last_years)
    },
    addRowToLastIncomeData() {
      this.$store.commit('profile/addRowToLastIncomeData')
    },
  }
}
</script>

<style lang="scss">
.b-tooltip.is-multiline.is-medium .tooltip-content {
  width: 340px;
  text-align: left;
  font-size: 0.8em;
}
.workkind-item .b-tooltip.is-multiline.is-medium .tooltip-content {
  max-width: 220px;
}
.tnd-input-label {
  text-transform: uppercase;
}
.calendar-add-row {
  a {
    display: flex;
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
.carousel-items {
  overflow: inherit !important;
}
.fade-enter-active {
  display: none !important;
}
.carousel-item-title-small {
  font-size: 16px;
}
</style>
