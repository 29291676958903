<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <h3 class="title carousel-item-title mb-6">
        Общие настройки профиля
      </h3>

      <div class="mb-7">
        <div class="columns">
          <div class="column pb-0">
            <tnd-input
                inputLabel="ВАШЕ ФИО"
                inputType="name"
                :maxLength="100"
                v-model="formData.common.name.value"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.name) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
                inputLabel="ДОЛЖНОСТЬ"
                inputType="post"
                :maxLength="100"
                v-model="formData.common.position.value"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.position) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
                inputLabel="ПУБЛИЧНЫЙ E-mail"
                v-model="formData.common.public_email.value"
                type="email"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.public_email) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
                inputLabel="ВАШ ТЕЛЕФОН"
                v-model="formData.common.public_phone.value"
                inputType="phone"
                :maxLength="13"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.public_phone) }}
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
                inputLabel="РАБОЧИЙ ТЕЛЕФОН"
                v-model="formData.common.work_phone.value"
                inputType="phone"
                :maxLength="13"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.work_phone) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
                inputLabel="ВЕБ-САЙТ"
                type="url"
                v-model="formData.common.website.value"
                :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.common.website) }}
            </p>
          </div>
        </div>

        <tnd-input
            inputLabel="О СЕБЕ"
            inputType="textarea"
            :maxLength="formData.common.bio.lengthParams.maxLength"
            v-model="formData.common.bio.value"
            :disabled="status"
        />
        <p class="has-text-danger">
          {{ getErrorMessage(formData.common.bio) }}
        </p>
      </div>

      <div class="has-text-centered mb-2">
        <button
            :disabled="status"
            @click="save('common')"
            class="button is-normal main-button purple"
        >
          <span>СОХРАНИТЬ</span>
        </button>
      </div>
    </section>
  </b-carousel-item>
</template>


<script>

import TndInput from '@/components/common/TndInput.vue'
import validate from '../services/validate.service.js'

const disableStatus = 18
export default {
  props: ['getErrorMessage', 'save'],
  components: {
    TndInput,
  },
  name: 'ProfileCommon',
  computed: {
    formData() {
      return this.$store.getters['profile/profileData']
    },
    status() {
      return this.$store.getters['profile/orgStatus'] === disableStatus
    }
  },
  created() {
    for (let i in this.formData.common) {
      this.$watch(
          () => this.formData.common[i].value,
          () => {
            validate(this.formData.common[i])
          }
      )
    }
  },

}
</script>
