<template>
  <b-carousel-item>
    <section class="p-6 shadow-d notifications-content">
      <h3 class="title carousel-item-title mb-6">Доступные подписки</h3>
      <div class="mb-6">
        <div
          v-for="item in notificationsData"
          :key="item.id"
          class="is-flex is-align-items-center cursor-pointer"
          @click="toggleVisibility(item)"
        >
          <div class="mr-1 pt-1">
            <BoxChecked v-if="formData.notifications.indexOf(item.id.toString()) != -1" />
            <BoxUnchecked v-else />
          </div>
          <div class="p-1 is-size-6 has-text-weight-medium">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="has-text-centered mt-3">
        <button
          @click="save('notifications')"
          class="button is-normal main-button purple"
        >
          <span>СОХРАНИТЬ</span>
        </button>
      </div>
    </section>
  </b-carousel-item>
</template>


<script>
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import _ from 'lodash'

export default {
  props: ["getErrorMessage", "save"],
  components: {
    TndInput,
    TndNotice,
    BoxChecked,
    BoxUnchecked,
  },
  name: "ProfileNotifications",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
  },
  methods: {
    toggleVisibility(item) {
      let notifications = _.cloneDeep(this.formData.notifications);
      const elementIndex = this.formData.notifications.indexOf(item.id);
      if (this.formData.notifications.indexOf(item.id) != -1)
        notifications.splice(elementIndex, 1)
      else
        notifications.push(item.id)
      this.$store.commit('profile/setNotifications', notifications)
    }
  },
  data() {
    return {
      notificationsData: [
        {'id': '1', 'name': 'Уведомлять о появлении новых тендеров'},
        {'id': '2', 'name': 'Уведомлять о завершении приема заявок'},
        {'id': '3', 'name': 'Уведомлять об изменении статуса моей заявки на тендер'},
        {'id': '4', 'name': 'Уведомлять об изменении статуса моего контрагента'},
        {'id': '8', 'name': 'Уведомлять об изменении содержания тендера'},
        {'id': '9', 'name': 'Уведомлять о готовности печати чистовой формы КС2'},
        {'id': '10', 'name': 'Уведомлять о готовности печати чистовой формы КС3'},
        {'id': '15', 'name': 'Оповещение о необходимости корректировки акта КС-2'},
        {'id': '16', 'name': 'Оповещение о необходимости корректировки акта КС-3'}
      ]
    }
  }
};
</script>


<style scoped>
/* from tablet */
@media screen and (min-width: 768px) {
  .tnd-notice-custom {
    margin-top: 2.2em;
    width: 100%;
  }
}
</style>

