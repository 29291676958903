<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <h3 class="title carousel-item-title mb-5">Я делегировал</h3>
      <div
        class="request-files__notice-container"
      >
        <tnd-notice
          v-for="(notice, index) of notices"
          :key="index"
          :text="notice.text"
        />
      </div>

      <tnd-table
        :data="myDelegations"
        :columns="myTableColumns"
        class="mt-6"        
      >
        <template v-slot="{props, column}">
          <div class="delegation-column-is-active" v-if="column.field === 'is_active'">
            <img src="../assets/svg/green-check-icon.svg" v-if="props.row[column.field]">
            <img src="../assets/svg/red-cross-icon.svg" v-else>
          </div>
          <div class="delegation-column-tools" v-else-if="column.field === 'tools'">
            <img src="../assets/svg/gray-edit-icon.svg" @click="openDelegationEditModal(props)">
            <img @click="deleteDelegation($event, props)" src="../assets/svg/trash-icon.svg">
          </div>
        </template>
      </tnd-table>

      <profile-delegation-add />
      <profile-delegation-edit 
        v-if="isEditDelegationModal" 
        :isEditDelegationModal="isEditDelegationModal" 
        @closeEditModal="isEditDelegationModal = false" 
        :userInfo="editDelegationModalData"/>
            
      <h3 class="title carousel-item-title mt-6">Мне делегировали</h3>
      <div
        class="request-files__notice-container"
      >
        <tnd-notice
          v-for="(notice, index) of otherNotices"
          :key="index"
          :text="notice.text"
        />
      </div>

      <tnd-table
        :data="otherDelegations"
        :columns="tableColumns"
        class="mt-6"
      ></tnd-table>
      
    </section>
  </b-carousel-item>
</template>


<script>
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import TndTable from "@/components/common/TndTable.vue";
import ProfileDelegationAdd from './profileDelegationAdd.vue';
import ProfileDelegationEdit from './profileDelegationEdit.vue';
import { urls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  props: ["getErrorMessage", "save"],
  components: {
    TndInput,
    TndNotice,
    TndTable,
    ProfileDelegationAdd,
    ProfileDelegationEdit,
  },
  name: "ProfileDelegation",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
    myDelegations() {
      return this.formData.delegations.my_delegations.map(item => {
        return {
          id: item.id,
          user_id: item.user_id,
          username: item.name,
          is_active: item.is_active,
          type: item.type == 1 ? 'Постоянное' : 'Временное',
          delegation_type: item.type.toString(),
          tools: '',
          date_from: item.date_from,
          date_to: item.date_to,
        }
      })
    },
    otherDelegations() {
      return this.formData.delegations.other_delegations.map(item => {
        return {
          username: item.name,
          is_active: item.is_active,
          type: item.type == 1 ? 'Постоянное' : 'Временное',
          tools: ''
        }
      })
    }
  },
  data() {
    return {
      isEditDelegationModal: false,
      editDelegationModalData: {},
      myTableColumns: [
        {
          field: 'username',
          label: 'пОЛЬЗОВАТЕЛЬ',
        },
        {
          field: 'is_active',
          label: 'активировать делегирование',
        },
        {
          field: 'type',
          label: 'ТИП делегирования',
        },
        {
          field: 'tools',
          label: '',
        }
      ],
      tableColumns: [
        {
          field: 'username',
          label: 'Кто делегировал',
        },
        {
          field: 'type',
          label: 'ТИП делегирования',
        },
      ],
      notices: [
        {
          text: "Внимание! Пользователь которому отправляется запрос должен быть уже зарегистрирован в системе.",
        },
      ],
      otherNotices: [
        {
          text: "После работы под делегированными вам правами выбранного пользователя, необходимо выйти из системы и войти заново под своей учетной записью",
        },
      ]
    }
  },
  methods: {
    openDelegationEditModal(props) {
      this.editDelegationModalData = this.myDelegations[props.index]
      this.isEditDelegationModal = true
    },
    deleteDelegation(e, props) {
      if (e.isTrusted) {
        this.$buefy.dialog.confirm({
          message: 'Вы уверены, что хотите удалить выбранную позицию?',
          cancelText: 'Отмена',
          confirmText: 'Да',
          onConfirm: () => {
            const currentDelegation = this.myDelegations[props.index]
            if (currentDelegation) {
              httpClient
                .delete(urls.apiUSerProfileDelegationUrl+'/'+currentDelegation.id)
                .then(({ data }) => {
                  if (!data) throw "Couldn't get user search information";            
                  if (data.success == true) {
                    this.$buefy.toast.open({
                      message: 'Успешно удалено',
                      type: "is-success",
                      duration: 4000
                    });
                    this.$store.commit('profile/updateDelegations', data.delegations)
                  }
                })
                .catch((error) => {
                  console.info("User Profile Loading Error: ", error);
                });
            }
          }
        })
      }
    }
  }
};
</script>


<style lang="scss">

.carousel-item-title {
  font-size: 22px;
}

.delegation-column-is-active {
  + div {
    display: none;
  }
}

.delegation-column-tools img {
  cursor: pointer;
  + img {
    margin-left: 5px;
  }
}

.modal-card .modal-card-foot {
  background: white;
  border: none;
  padding: 0;
}

/* from tablet */
@media screen and (min-width: 768px) {
  .tnd-notice-custom {
    margin-top: 2.2em;
    width: 100%;
  }
}
</style>

