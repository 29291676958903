<template>
  <div class="tnd-notice">
      <div class="tnd-notice__content">
      <slot name="icon">
          <warning-icon/>
      </slot>
      <div class="tnd-notice__text" v-html="text">
      </div>
      </div>
  </div>
</template>

<script>
import WarningIcon from '../icons/WarningIcon.vue'
export default {
  components: { WarningIcon },
    name: 'TndNotice',
    props: {
        status: {
            type: String,
            default: 'warning',
        },
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.tnd-notice {
    display: inline-block;
    background-color: #FFEDF6;
    border: 1px solid #A01B5B;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 1em;

    & + & {
        margin-top: 14px;
    }

    &__text {
        color: #A01B5B;
        font-weight: 350;
        font-size: 1em;
        line-height: 1.2em;
        padding-left: 13px;
    }

    @media screen and (min-width: 1024px) {
        &__content {
            display: flex;
        }
    }
}

</style>