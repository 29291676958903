<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.833 13H1.16634C0.706104 13 0.333008 13.3731 0.333008 13.8333C0.333008 14.2936 0.706104 14.6667 1.16634 14.6667H12.833C13.2932 14.6667 13.6663 14.2936 13.6663 13.8333C13.6663 13.3731 13.2932 13 12.833 13Z" :fill="color"/>
    <path d="M0.333008 12.168L0.333008 13.8346C0.333008 14.2949 0.706104 14.668 1.16634 14.668C1.62658 14.668 1.99967 14.2949 1.99967 13.8346L1.99967 12.168C1.99967 11.7077 1.62658 11.3346 1.16634 11.3346C0.706104 11.3346 0.333008 11.7077 0.333008 12.168Z" :fill="color"/>
    <path d="M12 12.168V13.8346C12 14.2949 12.3731 14.668 12.8333 14.668C13.2936 14.668 13.6667 14.2949 13.6667 13.8346V12.168C13.6667 11.7077 13.2936 11.3346 12.8333 11.3346C12.3731 11.3346 12 11.7077 12 12.168Z" :fill="color"/>
    <path d="M6.99988 10.4987C6.82711 10.5 6.65821 10.4476 6.51654 10.3487L3.18321 7.9987C3.00355 7.87126 2.88166 7.67786 2.84419 7.46081C2.80671 7.24375 2.85669 7.02068 2.98321 6.84037C3.04637 6.75024 3.12675 6.67352 3.21972 6.61462C3.31268 6.55573 3.4164 6.51583 3.52486 6.49724C3.63333 6.47864 3.74441 6.48172 3.85169 6.50628C3.95896 6.53085 4.06031 6.57642 4.14988 6.64037L6.99988 8.63203L9.83321 6.4987C10.01 6.36609 10.2323 6.30915 10.4511 6.34041C10.6699 6.37167 10.8673 6.48856 10.9999 6.66537C11.1325 6.84218 11.1894 7.06443 11.1582 7.28322C11.1269 7.50201 11.01 7.69943 10.8332 7.83203L7.49988 10.332C7.35563 10.4402 7.18019 10.4987 6.99988 10.4987Z" :fill="color"/>
    <path d="M6.99935 8.83333C6.77834 8.83333 6.56637 8.74554 6.41009 8.58926C6.25381 8.43298 6.16602 8.22101 6.16602 8V1.33333C6.16602 1.11232 6.25381 0.900358 6.41009 0.744078C6.56637 0.587797 6.77834 0.5 6.99935 0.5C7.22036 0.5 7.43232 0.587797 7.5886 0.744078C7.74489 0.900358 7.83268 1.11232 7.83268 1.33333V8C7.83268 8.22101 7.74489 8.43298 7.5886 8.58926C7.43232 8.74554 7.22036 8.83333 6.99935 8.83333Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
  props: {
    color: {
      type: String,
      default: '#6F6F6F'
    }
  }
}
</script>

<style>

</style>