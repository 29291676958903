<template>
  <section class="main-wrapper">
    <div class="main-content">

      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />
      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="is-flex is-align-items-center is-justify-content-space-between mb-5-5"
        >
          <div class="is-flex is-align-items-center">
            <span @click="goBack" class="cursor-pointer mr-5">
              <ArrowLeftBigIcon />
            </span>

            <h2
              class="main-title is-size-3-5-mobile has-text-left has-text-centered-mobile mr-4"
            >
              Мой аккаунт
            </h2>
          </div>
        </div>

        <div class="columns is-desktop">
          <div class="column is-3-desktop pr-5-5-desktop">
            <div
              v-for="(item, index) of Object.values(carousels)"
              :key="index"
              class="btn-wrapper"
            >
              <button
                :disabled="item.disabled"
                @click="curentCarousel = index"
                class="profile-btn main-button button is-fullwidth has-text-left is-justify-content-left mb-2"
                v-bind:class="[
                  index === curentCarousel ? 'purple' : 'transparent'
                ]"
                :style="item.isError ? { border: '2px solid #A2165B' } : {}"
              >
                <span>
                  {{ item.title }}
                  <b v-if="item.label">2</b>
                </span>
              </button>
              <div v-if="item.isError" class="profile-atention">
                <img  
                  v-if="index === curentCarousel"
                  src="../assets/svg/atent-white.svg" alt="" />
                <img 
                  v-else
                  src="../assets/svg/atent.svg" alt="" />
              </div>
            </div>
            <button v-if="status !== 17 " class="button mt-6 edit-button" @click="onChangeStatus">
              <img src="../assets/svg/edit-icon.svg" alt="" />
              <span>Редактировать</span>
            </button>
            <button v-if="status === 17" class="button mt-6 edit-button"  @click="resetChanges">
              <span>Отменить изменения</span>
            </button>
            <button v-if="status === 17" class="button mt-6 edit-button"  @click="sendToCheck">
              <img src="@/assets/svg/check-circle-icon.svg" alt="">
              <span>На проверку</span>
            </button>
          </div>
          <div class="column is-9-desktop">
            <b-carousel
              v-model="curentCarousel"
              animated="fade"
              :autoplay="false"
              :arrow="false"
              :indicator="false"
              class="br-3"
            >
              <profile-common :getErrorMessage="getErrorMessage" :save="save" />
              <profile-account
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-contragent
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-categories
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-documents
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-notifications
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-requests
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
              <profile-delegation
                :getErrorMessage="getErrorMessage"
                :save="save"
              />
            </b-carousel>
          </div>
          <!-- column -->
        </div>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from '@/components/common/Header.vue'
import FooterVue from '@/components/common/Footer.vue'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import TndNotice from '@/components/common/TndNotice.vue'
import profileCommon from '@/profile/profileCommon.vue'
import ArrowLeftBigIcon from '@/components/icons/ArrowLeftBigIcon'
import TndInput from '@/components/common/TndInput.vue'
import httpClient from '@/services/http.service'
import { urls } from '@/config'
import ProfileAccount from '@/profile/profileAccount.vue'
import ProfileContragent from '@/profile/profileContragent.vue'
import ProfileNotifications from '@/profile/profileNotifications.vue'
import ProfileDocuments from '@/profile/profileDocuments.vue'
import ProfileDelegation from '@/profile/profileDelegation.vue'
import ProfileRequests from '@/profile/profileRequests.vue'
import ProfileCategories from '@/profile/profileCategories.vue'

export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
    ArrowLeftBigIcon,
    TndInput,
    TndNotice,
    profileCommon,
    ProfileAccount,
    ProfileContragent,
    ProfileNotifications,
    ProfileDocuments,
    ProfileDelegation,
    ProfileRequests,
    ProfileCategories
  },
  name: 'Profile',
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Настройки аккаунта',
          link: null
        }
      ],
      curentCarousel: 0,
      carousels: {
        common: { title: 'Настройки профиля',  ref: true, isError:false  },
        account: { title: 'Учетные данные и пароль', ref: true, isError:false },
        contractors_data: { title: 'Данные контрагента', ref: true, isError:false },
        categories: { title: 'Категории и виды работ', ref: true, isError:false },
        documents: { title: 'Документы' },
        notifications: { title: 'Настройка уведомлений' },
        requests: { title: 'Запрос/Приглашение' },
        delegations: { title: 'Делегирование' }
      }
    }
  },
  computed: {
    formData() {
      return this.$store.getters['profile/profileData']
    },
    status() {
      return this.$store.getters['profile/orgStatus']
    }
  },
  methods: {
    showErrorCategories() {
      const errorChapters = []
      Object.keys(this.formData).forEach((chapter) => {
        Object.keys(this.carousels).forEach((i) => {
          if (i === chapter) {
            Object.keys(this.formData[chapter]).forEach((field) => {
              if (
                this.formData[chapter][field].error &&
                this.formData[chapter][field].error.length > 0
              ) {
                this.carousels[i].isError = true
                errorChapters.push(this.carousels[i].title)
              }
            })
          }
        })
      })
      if (errorChapters.length > 0) {
        const str = errorChapters.join(', ')
        this.$buefy.toast.open({
          message: `Для отправки на проверку необходимо исправить ошибки в: ${str} `,
          type: 'is-danger',
          duration: 5000
        })
      }

    },
    resetChanges() {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены, что хотите отменить изменения?',
        onConfirm: () => window.location.reload(),
        cancelText: 'Нет',
        confirmText: 'Да',
      })
    },
    sendToCheck() {
      let objectToSend = {}
      Object.keys(this.carousels).forEach(i => {
        if (this.carousels[i].ref) {
          objectToSend[i] = this.getChapterData(i)
        }
      })
      objectToSend.send_to_review = 1

      httpClient
          .post(
              urls.apiUSerProfileUrl,
              objectToSend,
              {
                headers: {'Content-Type': 'application/json'}
              }
          )
          .then(({data}) => {
            if (data.profile) {
              this.$store.commit('profile/setProfileData', data.profile)
              this.$store.commit('profile/setStatus', data.profile.organization_status)
              if (data.success) {
                this.$buefy.toast.open({
                  message: 'Данные сохранены',
                  type: 'is-success',
                  duration: 3000
                })
                 Object.keys(this.carousels).forEach((i) => {
                   this.carousels[i].isError = false
                 })
              }
              return
            }
            Object.entries(this.formData).map(([chapterName, chapterFormData]) => {
              if (chapterFormData)
                Object.entries(chapterFormData).map(([fieldName, fieldData]) => {
                  if (data.errors[chapterName] && data.errors[chapterName][fieldName])
                    if(this.formData[chapterName][fieldName]) {
                      this.formData[chapterName][fieldName].error = data.errors[chapterName][fieldName]
                    }
                })
            })
            this.showErrorCategories()
          })
    },
    goBack() {
      this.$router.go(-1)
    },
    resetErrors() {
      Object.entries(this.formData).map(([chapterName, chapterFormData]) => {
        if (chapterFormData)
          Object.entries(chapterFormData).map(([fieldName, fieldData]) => {
            if (chapterName != 'notifications')
              this.formData[chapterName][fieldName].error = ''
          })
      })
    },
    loadProfile() {
      this.$store.dispatch('profile/loadProfile')
      this.$store.dispatch('profile/loadUsers')
    },
    onChangeStatus() {
      this.$store.commit('profile/setStatus', 17)
    },
    save(chapter) {
      let isError = false
      Object.keys(this.formData[chapter]).forEach((i) => {
        if (
          this.formData[chapter][i].error &&
          this.formData[chapter][i].error.length > 0
        ) {
          isError = true
        }
      })
      if (chapter === 'contractors_data') {
        this.formData.contractors_data.turnover_last_years.forEach((el) => {
          if (el.error && el.error.length > 0) {
            isError = true
          }
        })
      }
      if (isError) {
        this.$buefy.toast.open({
          message: 'Не удалось сохранить данные',
          type: 'is-danger',
          duration: 500
        })
        return
      }
      this.resetErrors()
      let formData = new FormData()
      let dataToSend = {}
      const headers =
        chapter != 'documents'
          ? { 'Content-Type': 'application/json' }
          : { 'Content-Type': 'multipart/form-data' }
      if (
        chapter != 'notifications' &&
        chapter != 'documents' &&
        chapter != 'categories' &&
        chapter != 'contractors_data'
      ) {
        Object.entries(this.formData[chapter]).map(([fieldName, fieldData]) => {
          dataToSend[fieldName] =
            fieldName == 'turnover_last_years' ? fieldData : fieldData.value
        })
      } else if (chapter == 'categories') {
        dataToSend = this.formData[chapter]
      } else if (chapter != 'documents') {
        dataToSend = this.getChapterData(chapter)
        console.log(111)
      } else {
        dataToSend = []
        this.formData.documents.map((docTemplate) => {
          if (docTemplate.files) {
            const filteredFiles = docTemplate.files.filter(
              (item) => item.id == null
            )
            if (filteredFiles)
              filteredFiles.map((doc) => {
                formData.append(['files-' + docTemplate.id], doc)
              })
            dataToSend.push({ id: docTemplate.id, title: docTemplate.name })
          }
        })
      }
      formData.append(chapter, JSON.stringify(dataToSend))
      httpClient
        .post(
          urls.apiUSerProfileUrl,
          chapter == 'documents' ? formData : { [chapter]: dataToSend },
          {
            headers: headers
          }
        )
        .then(({ data }) => {
          if (data.profile) {
            this.$store.commit('profile/setProfileData', data.profile)
          }
          if (data.success) {
            this.$buefy.toast.open({
              message: 'Данные сохранены',
              type: 'is-success',
              duration: 3000
            })
            return
          }

          throw data.errors
        })
        .catch((errors) => {
          console.info('User Profile Saving Error: ', errors)

          if (errors[chapter]) {
            Object.entries(this.formData[chapter]).map(
              ([fieldName, fieldData]) => {
                this.formData[chapter][fieldName].error =
                  errors[chapter][fieldName]
              }
            )
          }

          this.$buefy.toast.open({
            message: 'Не удалось сохранить данные',
            type: 'is-danger',
            duration: 5000
          })
        })
    },
    getErrorMessage(error) {
      const _error = Array.isArray(error.error) ? error.error[0] : error.error
      const frontError = (error.value === '' && error.required) ? 'Поле обязательно для заполнения' : null;
      if(error.value === '' && !error.required){
        error.error = ''
      }
      return _error || frontError
    },

    getChapterData(chapter) {
      const dataToSend = {}
      Object.entries(this.formData[chapter]).map(([fieldName, fieldData]) => {
        dataToSend[fieldName] =
            fieldName == 'turnover_last_years' ? fieldData : fieldData.value
      })
      if (chapter == 'contractors_data') {
        const lastIncomeData = dataToSend.turnover_last_years
        const lastIncomeString = lastIncomeData
            .map(
                (item) => '(' + item.year + ' 1-4 кв.) ' + item.income + ' тыс. р.'
            )
            .join(' / ')
        dataToSend.turnover_last_years = lastIncomeString
      }
      return dataToSend
    }
  },
  mounted() {
    this.loadProfile()
  }
}
</script>

<style lang="scss" scoped>
.columns .button span b {
  height: 19px;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  border-radius: 50%;
  background: #a01b5b;
  color: white;
  padding: 0 6px;
  min-width: 19px;
}
.edit-button {
  width: 100%;
  border: 1px solid #a2165b;
  border-radius: 3px;
  color: #a2165b;
  padding: 18px;
  height: 46px;
  font-size: 12px;
  text-transform: uppercase;
  img {
    margin-right: 5px;
  }
  + .edit-button {
    margin-top: 20px !important;
  }
}
.carousel-item-title {
  font-size: 1.43em;
  font-weight: 500;
}

.tnd-notice {
  font-size: 0.95em;
  margin-bottom: 1.5em;
  width: 100%;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.profile-atention {
  position:absolute;
  font-size: 2em;
  color: #a2165b;
  right: 30px;
}

/* from tablet */
@media screen and (min-width: 768px) {
  .tnd-notice {
    margin-top: 2.4em;
  }
}
</style>
