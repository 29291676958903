<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4167 19.1668H2.58333C2.0971 19.1668 1.63079 18.9737 1.28697 18.6299C0.943154 18.286 0.75 17.8197 0.75 17.3335V4.50016C0.75 4.01393 0.943154 3.54762 1.28697 3.2038C1.63079 2.85998 2.0971 2.66683 2.58333 2.66683H4.41667V0.833496H6.25V2.66683H11.75V0.833496H13.5833V2.66683H15.4167C15.9029 2.66683 16.3692 2.85998 16.713 3.2038C17.0568 3.54762 17.25 4.01393 17.25 4.50016V17.3335C17.25 17.8197 17.0568 18.286 16.713 18.6299C16.3692 18.9737 15.9029 19.1668 15.4167 19.1668ZM2.58333 8.16683V17.3335H15.4167V8.16683H2.58333ZM2.58333 4.50016V6.3335H15.4167V4.50016H2.58333ZM9.91667 15.5002H8.08333V13.6668H6.25V11.8335H8.08333V10.0002H9.91667V11.8335H11.75V13.6668H9.91667V15.5002Z" fill="#A01B5B"/>
  </svg>
</template>


<script>
export default {
  name: 'CalendarAddIcon',
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>

<style>

</style>
