const validate = (fieldObj, juridicalType) => {
  //1 year проверка оборота средств
  if (Array.isArray(fieldObj)) {
    const arr = []
    fieldObj.forEach((i) => {
      if (i.income) arr.push(i.income)
    })
    fieldObj.forEach((i) => {
      if (Math.max.apply(null, arr) === 0) {
        fieldObj.forEach((i) => {
          i.error = 'Заполните минимум 1 год'
        })
      }
      if (i.year.length === 0) {
        fieldObj.forEach((i) => {
          i.error = 'Заполните минимум 1 год'
        })
      }
    })
    fieldObj.forEach((i) => {
      if ((i.year.length > 0 || typeof i.year === 'object') && i.income > 0) {
        fieldObj.forEach((i) => {
          i.error = ''
        })
      }
    })
  }

  let { value, lengthParams, regexp } = fieldObj
  //2 проверка на правильность заполнения регулярным выражением
  if (regexp) {
    if (!regexp.test(value)) {
      fieldObj.error = 'не верно заполнено поле'
    } else {
      fieldObj.error = ''
    }
  }

  //3 проверка на количество символов
  if (!lengthParams) return

  switch (lengthParams.type) {
    case 'exact': // проверка на точное количество символов
      if (typeof lengthParams.exact === 'number') {
        if (value.length !== lengthParams.exact) {
          fieldObj.error = `Значение должно содержать ${lengthParams.exact} символов`
        } else {
          fieldObj.error = ''
        }
      }
      break

    case 'range': // проверка на  количество символов в интервале
      if (lengthParams.range) {
        if (value.length < lengthParams.range[0]) {
          fieldObj.error = `Значение должно содержать от ${lengthParams.range[0]} до ${lengthParams.range[1]} символов`
        } else {
          fieldObj.error = ''
        }
      }
      break

    case 'juridical': //проверка на количество символов по типу юр лица
      if (juridicalType == 2) {
        if (
          lengthParams.juridical.IP &&
          value.length !== lengthParams.juridical.IP
        ) {
          fieldObj.error = `Значение должно содержать ${lengthParams.juridical.IP} символов`
        } else {
          fieldObj.error = ''
        }
      } else {
        if (
          lengthParams.juridical.YurLico &&
          value.length !== lengthParams.juridical.YurLico
        ) {
          fieldObj.error = `Значение должно содержать ${lengthParams.juridical.YurLico} символов`
        } else {
          fieldObj.error = ''
        }
      }
      break
    case 'minLength': // проверка на минимальное количество символов
      if (lengthParams.minLength) {
        if (value.length < lengthParams.minLength) {
          fieldObj.error = `Значение должно содержать от ${lengthParams.minLength} символов`
        } else {
          fieldObj.error = ''
        }
      }
      break
  }
}
export default validate
