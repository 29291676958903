<template>
  <section>

      <button class="button mt-6 border-button" @click="isComponentModalActive = true">
        <img src="../assets/svg/pink-plus-icon.svg" alt="">
        <span>Добавить</span>
      </button>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Приглашение</p>
            <button
              type="button"
              class="delete"
              @click="isComponentModalActive = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="E-mail или логин пользователя">
              <b-autocomplete
                v-model="username"
                :data="filteredDataArray"
                clearable
                field="username"
                @select="option => selected = option">
                <template #empty>Не найдено</template>
              </b-autocomplete>
            </b-field>

            <tnd-input
              inputLabel="Тип приглашения"
              v-model="request_type"
              inputType="select"
              :options="[
                { value: '1', name: 'Передать права полностью' },
                { value: '2', name: 'Присоединить к контрагенту' },
              ]"
            />
            <div class="has-text-centered mt-2">
              <button
                class="button is-normal main-button purple"
                @click="createRequest"
              >
                <span>Отправить</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>

import TndInput from "@/components/common/TndInput.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import { urls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  components: {
    TndInput,
    BoxChecked,
    BoxUnchecked,
  },
  name: 'ProfileRequestAdd',
  data() {
    return {
      isComponentModalActive: false,
      email: '',
      is_mass: '1',
      is_active: false,
      username: '',
      request_type: ''
    }
  },
  computed: {
      filteredDataArray() {
        return this.$store.getters['profile/userSearchData'].filter(option => {
          return (
            option.username
              .toString()
              .toLowerCase()
              .indexOf(this.username.toLowerCase()) >= 0
          )
        })
      }
  },
  methods: {
    toggleActivity() {
      this.is_active = !this.is_active
    },
    createRequest() {
      this.errors = []
      const dataToSend = {
        type_invitation_id: this.request_type,
        user_id: this.selected.id,
      }
      if (this.request_type && this.selected) {
        httpClient
          .post(urls.apiUSerProfileRequestUrl, dataToSend)
          .then(({ data }) => {
            if (!data) throw "Couldn't get user search information";            
            if (data.success == true) {
              this.isComponentModalActive = false
              this.$buefy.toast.open({
                message: 'Успешно',
                type: "is-success",
                duration: 4000
              });
              this.$store.commit('profile/updateRequests', data.requests)
            } else 
              this.errors = data.errors
          })
          .catch((error) => {
            console.info("User Profile Loading Error: ", error);
            this.errors = error
          });
      }
    },
  }
}
</script>

<style lang="scss">
.modal-card {
  padding: 40px;
  background: white;
  border-radius: 10px;
  max-width: 420px;
  .modal-card-head {
    background: white;
    border: none;
    padding: 0;
  }
  .main-button {
    width: 100%;
    text-transform: uppercase;
  }
  .modal-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.tnd-input-container .pt-1 + .tnd-input-label {
  min-height: 0;
}
</style>