<template>
  <b-carousel-item>
    <div v-if="formData.documents">
      <section class="p-6 shadow-d mb-5"
        v-for="category in categories"
        :key="category.id"
      >
        <h3 class="title carousel-item-title mb-6 documents-title">
          <span>{{ category.name }}</span>
        </h3>
        <div class="request-files">
          <div
            class="request-files__notice-container"
            v-if="notices.length && filesUploaded"
          >
            <tnd-notice
              v-for="(notice, index) of notices"
              :key="index"
              :text="notice.text"
            />
          </div>
          <div
              class="request-files__notice-container"
              v-else-if="errorInCategories.length"
          >
            <template v-if="showValidateNotice(category.id)">
              <tnd-notice
                  v-for="(notice, index) of notices"
                  :key="index"
                  :text="notice.text"
              />
            </template>
          </div>
          <div class="request-files__sections">
            <div class="request-files__section"
              v-for="docTemplate in getDocTemplatesByCategory(category.id)"
              :key="docTemplate.id"
            >
                <div class="section-info" :class="{'not-required-doc': !docTemplate.required}">
                    <div class="section-title">
                    <span> 
                      {{ docTemplate.name }} {{ docTemplate.description }} 
                      <b v-if="!docTemplate.required">не обязательно</b>
                      <b class="danger" v-else>{{
                          docTemplate.files !== null
                              ? docTemplate.files[0] instanceof  Object
                                  ? '' :docTemplate.error : docTemplate.error
                        }}</b>
                    </span>
                </div>
              </div>
              <div class="columns is-gapless">
                <div class="column">
                  <tnd-profile-upload-file :name="'file-'+docTemplate.id" :disabled="status || filesUploaded"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="has-text-centered mt-6">
        <button
            :disabled="status"
            @click="validate()"
            class="button is-normal main-button purple"
        >
          <span>СОХРАНИТЬ</span>
        </button>
      </div>
    </div>
    <div v-else><p>Чтобы загрузить документы необходимо заполнить информацию во вкладке "Данные контрагента"</p></div>
  </b-carousel-item>
</template>


<script>
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import _ from 'lodash'
import ExclamationInCircleIcon from "@/components/icons/ExclamationInCircleIcon.vue";
import TndProfileUploadFile from "../components/common/TndProfileUploadFile.vue";
import {validateDocuments} from "@/services/validateDocument.service";

const disableStatus = 18
export default {
  props: ["getErrorMessage", "save"],
  data() {
    return {
      filesUploaded: false,
      categories: [
        {
          "id": 1,
          "name": "Юридические",
        },
        {
          "id": 2,
          "name": "Финансовые",
        },
        {
          "id": 3,
          "name": "Производство",
        }
      ],
      notices: [
        {
          text: "Обязательные документы не загружены. Загрузите файлы.",
        },
      ],
      errorInCategories: [],
    }
  },
  components: {
    TndInput,
    TndNotice,
    BoxChecked,
    BoxUnchecked,
    ExclamationInCircleIcon,
    TndProfileUploadFile,
  },
  name: "ProfileDocuments",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
    status() {
      return this.$store.getters['profile/orgStatus'] === disableStatus
    }
  },
  methods: {
    getDocTemplatesByCategory(categoryId) {
      return (this.formData.documents) ?
        this.formData.documents.filter(item => item.doc_category_id == categoryId).sort((a, b) => b.required - a.required)
        :
        []
    },
    toggleVisibility(item) {
      let notifications = _.cloneDeep(this.formData.notifications);
      const elementIndex = this.formData.notifications.indexOf(item.id);
      if (this.formData.notifications.indexOf(item.id) != -1)
        notifications.splice(elementIndex, 1)
      else
        notifications.push(item.id)
      this.$store.commit('profile/setNotifications', notifications)

    },
    validate() {
      this.errorInCategories = validateDocuments(this.formData.documents)
      if (this.errorInCategories.length) {
        this.$buefy.toast.open({
          message: 'Загрузите все обязательные документы',
          type: 'is-danger',
          duration: 4000
        })
        return
      }
      this.save('documents')
    },
    showValidateNotice(id) {
      return this.errorInCategories.find(item => item['doc_category_id'] === id)
    }
  },
}
</script>


<style scoped lang="scss">
.gray-text {
  color: #828282;
  font-size: 14px;
  font-weight: normal;
}

.request-files {
  .not-required-doc,  {
    .section-title span {
      b {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: normal;
        color: #828282;
      }
    }
  }
  &__notice-container {
    margin-bottom: 33px;
  }
  .request-files__sections {
    display: flex;
    flex-wrap: wrap;
  }

  &__section {
    padding: 27px 10px 37px;
    width: 50%;
    .section-info {
      padding-bottom: 10px;

      .section-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 1.2;
        color: #323232;
        margin-bottom: 9px;

        span {
          margin-right: 14px;
          font-size: 12px;
          position: relative;
          padding-right: 100px;
          line-height: 1.2;
          .danger {
            position: absolute;
            right: 0;
            top: 0;
            font-weight: normal;
            color: #f14668 !important;
          }
        }
      }

      .section-description {
        font-style: normal;
        font-weight: 350;
        font-size: 13px;
        line-height: 1.05em;
        color: #6f6f6f;
      }
    }
  }
}
.documents-title {
  font-size: 22px;
  > span {
    padding-right: 10px;
  }
}
/* from tablet */
@media screen and (min-width: 768px) {
  .tnd-notice-custom {
    margin-top: 2.2em;
    width: 100%;
  }
}
</style>

