<template>
  <section>

      <button class="button mt-6 border-button" @click="isComponentModalActive = true">
        <img src="../assets/svg/pink-plus-icon.svg" alt="">
        <span>Добавить</span>
      </button>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Делегирование</p>
            <button
              type="button"
              class="delete"
              @click="isComponentModalActive = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="E-mail или логин пользователя">
              <b-autocomplete
                v-model="username"
                :data="filteredDataArray"
                clearable
                field="username"
                @select="option => selected = option">
                <template #empty>Не найдено</template>
              </b-autocomplete>
            </b-field>

            <tnd-input
              inputLabel="тип делегирования"
              v-model="delegation_type"
              inputType="select"
              :options="[
                { value: '1', name: 'Постоянное' },
                { value: '2', name: 'Временное' },
              ]"
            />
            <tnd-input v-if="delegation_type == '2'"
              inputLabel="Дата начала"
              inputType="datepicker"
              datepickerType="date"
              datepickerFormat="YYYY-MM-DD"
              v-model="date_from"
            />
            <tnd-input v-if="delegation_type == '2'"
              inputLabel="Дата окончания"
              inputType="datepicker"
              datepickerType="date"
              datepickerFormat="YYYY-MM-DD"
              v-model="date_to"
            />
            <div 
              class="is-flex is-align-items-center cursor-pointer tnd-input-container"
              @click="toggleActivity">
              <div class="mr-1 pt-1">
                <BoxChecked v-if="is_active" />
                <BoxUnchecked v-else />
              </div>
              <div class="tnd-input-label">
                активировать делегирование
              </div>
            </div>
            <div v-if="errors" class="errors is-size-7-2">
              <p
                v-for="(error, field) in errors"
                :key="field"
                class="mb-3 has-text-danger"
              >
                {{ error[0] }}
              </p>
            </div>
            <div class="has-text-centered mt-2">
              <button
                class="button is-normal main-button purple"
                @click="createDelegation()"
              >
                <span>Отправить</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>

import TndInput from "@/components/common/TndInput.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import { urls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  components: {
    TndInput,
    BoxChecked,
    BoxUnchecked,
  },
  data() {
    return {
      isComponentModalActive: false,
      email: '',
      delegation_type: '1',
      is_active: false,
      data: [],
      username: '',
      selected: null,
      errors: [],
      date_from: '',
      date_to: ''
    }
  },
  computed: {
      filteredDataArray() {
        return this.$store.getters['profile/userSearchData'].filter(option => {
          return (
            option.username
              .toString()
              .toLowerCase()
              .indexOf(this.username.toLowerCase()) >= 0
          )
        })
      }
  },
  methods: {
    createDelegation() {
      this.errors = []
      const dataToSend = {
        type_id: parseInt(this.delegation_type),
        delegate_user_id: this.selected.id,
        active: this.is_active ? 1 : 0,
        date_from: this.date_from,
        date_to: this.date_to,
      }
      if (this.delegation_type && this.selected) {
        httpClient
          .post(urls.apiUSerProfileDelegationUrl, dataToSend)
          .then(({ data }) => {
            if (!data) throw "Couldn't get user search information";            
            if (data.success == true) {
              this.$buefy.toast.open({
                message: 'Успешно',
                type: "is-success",
                duration: 4000
              });
              this.isComponentModalActive = false
              dataToSend.type = dataToSend.type_id
              dataToSend.is_active = dataToSend.active
              dataToSend.name = this.username
              dataToSend.delegation_type = dataToSend.type_id
              this.$store.commit('profile/addDelegation', dataToSend)
            } else 
              this.errors = data.errors
          })
          .catch((error) => {
            console.info("User Profile Loading Error: ", error);
            this.errors = error
          });
      }
    },
    toggleActivity() {
      this.is_active = !this.is_active
    }
  }
}
</script>

<style lang="scss">
.modal-card {
  padding: 40px;
  background: white;
  border-radius: 10px;
  max-width: 420px;
  .modal-card-head {
    background: white;
    border: none;
    padding: 0;
  }
  .main-button {
    width: 100%;
    text-transform: uppercase;
  }
  .modal-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.tnd-input-container .pt-1 + .tnd-input-label {
  min-height: 0;
}
.modal-card .tnd-input-container .tnd-input-label {
  min-height: 0;
  + .tnd-container {
    margin-top: 7px;
  }
}
.autocomplete .input {
  height: 50px;
  margin-bottom: 15px;
  border: 1px solid #ededed;
  background: #fbfbfb;
  border-radius: 3px;
  padding: 1em;
}
.field .label {
  font-size: 14px;
  line-height: 1em;
  font-weight: 400;
  color: #6f6f6f;
  text-transform: uppercase;
}
.autocomplete .control.has-icons-right .icon.is-right {
  top: 7px
}
</style>