<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <h3 class="title carousel-item-title mb-5">Я пригласил</h3>
      <div
        class="request-files__notice-container"
      >
        <tnd-notice
          v-for="(notice, index) of notices"
          :key="index"
          :text="notice.text"
        />
      </div>

      <tnd-table
        :data="outcoming"
        :columns="myTableColumns"
        class="mt-6"        
      >
        <template v-slot="{props, column}">
          <div class="delegation-column-is-active" v-if="column.field === 'status'">
            <div class="tags has-addons" v-if="statuses[props.row[column.field]]">
              <span class="tag pl-1 pr-1" v-bind:class="statuses[props.row[column.field]].class">
                <span class="tag-dot"> • </span>
              </span>
              <span class="tag pl-0" v-bind:class="statuses[props.row[column.field]].class">{{ statuses[props.row[column.field]].text }}</span>
            </div>
          </div>
        </template>
      </tnd-table>

      <profile-request-add />

      <h3 class="title carousel-item-title mt-6">Меня пригласили</h3>

      <div v-if="incoming" class="requests-collapse">
        <b-collapse
          class=""
          animation="slide"
          v-for="(collapse, index) of incoming"
          :key="index"
          :open="false"
        >
          <template #trigger="props">
            <div
              class="is-flex is-justify-content-space-between"
              role="button"
            >
              <div
                class="collapse-title"
              >
                {{ collapse.data_formatted }}
                <div class="tags has-addons" v-if="statuses[collapse.status]">
                  <span class="tag pl-1 pr-1" v-bind:class="statuses[collapse.status].class">
                    <span class="tag-dot"> • </span>
                  </span>
                  <span class="tag pl-0" v-bind:class="statuses[collapse.status].class">{{ statuses[collapse.status].text }}</span>
                </div>
              </div>
              <a class="card-header-icon p-0 mw-1">
                <img
                  :class="{'collapse-icon-active': props.open}"
                  src="../assets/svg/arrow-down-icon.svg"
                  alt="plus"
                  class="hw-26 hw-38-tablet"
                />
              </a>
            </div>
          </template>
          <div class="pt-4 pb-4 collapse-text">
            <div class="content text-promo">
              <p>От кого: {{collapse.user_from}}</p>
              <p>Тип: {{collapse.type_string}}</p>
            </div>
            <div class="is-flex" v-if="collapse.status ==  1">
              <button
                @click="acceptRequest(collapse)"
                class="button is-normal main-button purple"
              >
                <span>Принять</span>
              </button>
              <button
                class="button is-normal main-button white"
                @click="declineRequest(collapse)"
              >
                <span>Отклонить</span>
              </button>
            </div>
          </div>
        </b-collapse>
      </div>
      <div v-else><p>У вас пока нет приглашений</p></div>

    </section>
  </b-carousel-item>
</template>


<script>
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import TndTable from "@/components/common/TndTable.vue";
import ProfileRequestAdd from './profileRequestAdd.vue';
import { urls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  props: ["getErrorMessage", "save"],
  components: {
    TndInput,
    TndNotice,
    TndTable,
    ProfileRequestAdd,
  },
  name: "ProfileRequests",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
    incoming() {
      return this.formData.requests.incoming.map(item => {
        return {
         type_string: item.type == 1 ? 'Передать права полностью' : 'Присоединить к контрагенту',
         data_formatted: item.date ? item.date.split(' ')[0] : '',
          ...item
        }
      })
    },
    outcoming() {
      return this.formData.requests.outcoming.map(item => {
        return {
          type_string: item.type == 1 ? 'Передать права полностью' : 'Присоединить к контрагенту',
          data_formatted: item.date ? item.date.split(' ')[0] : '',
          ...item
        }
      })
    }
  },
  data() {
    return {
      statuses: {
        1: {
          class: 'status-pending',
          text: 'отправлен'
        },
        2: {
          class: 'status-open',
          text: 'принят'
        },
        3: {
          class: 'status-declined',
          text: 'отклонен'
        },
      },
      isEditDelegationModal: false,
      editDelegationModalData: {},
      myTableColumns: [
        {
          field: 'user_to',
          label: 'пОЛЬЗОВАТЕЛЬ',
        },
        {
          field: 'type_string',
          label: 'ТИП приглашения',
        },
        {
          field: 'status',
          label: 'статус',
        },
        {
          field: 'data_formatted',
          label: 'дата создания',
        }
      ],
      notices: [
        {
          text: "Вы можете добавить новых участников к своему контрагенту либо передать управление контрагентом. Для этого в форме запроса укажите email или логин пользователя системы. <br><br>Внимание! Пользователь которому отправляется запрос должен быть уже зарегистрирован в системе.",
        },
      ],
      otherNotices: [
        {
          text: "После работы под делегированными вам правами выбранного пользователя, необходимо выйти из системы и войти заново под своей учетной записью",
        },
      ]
    }
  },
  methods: {
    declineRequest(request) {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены, что хотите отклонить этот запрос?',
        cancelText: 'Отмена',
        confirmText: 'Да',
        onConfirm: () => {
          httpClient
            .post(urls.apiUSerProfileRequestUrl + '/' + request.id + '/decline')
            .then(({ data }) => {
              if (!data) throw "Couldn't get user search information";            
              if (data.success == true) {
                this.isComponentModalActive = false
                this.$buefy.toast.open({
                  message: 'Успешно',
                  type: "is-success",
                  duration: 4000
                });
                this.$store.commit('profile/updateRequests', data.requests)
              } else 
                this.errors = data.errors
            })
            .catch((error) => {
              console.info("User Profile Loading Error: ", error);
              this.errors = error
            });
        }
      })
    },
    acceptRequest(request) {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены, что хотите подтвердить этот запрос?',
        cancelText: 'Отмена',
        confirmText: 'Да',
        onConfirm: () => {
          httpClient
            .post(urls.apiUSerProfileRequestUrl + '/' + request.id + '/accept')
            .then(({ data }) => {
              if (!data) throw "Couldn't get user search information";            
              if (data.success == true) {
                this.isComponentModalActive = false
                this.$buefy.toast.open({
                  message: 'Успешно',
                  type: "is-success",
                  duration: 4000
                });
                this.$store.commit('profile/updateRequests', data.requests)
              } else 
                this.errors = data.errors
            })
            .catch((error) => {
              console.info("User Profile Loading Error: ", error);
              this.errors = error
            });
        }
      })
    }
  }
}
</script>

<style lang="scss">
.carousel-item-title {
  font-size: 22px;
}
.collapse-icon-active {
  transform: rotate(180deg);
}
.collapse-title {
  display: flex;
  align-items: center;
  .tags {
    margin-left: 10px;
  }
}
.requests-collapse {
  .collapse {
    display: block;
    border-bottom: 1px solid #BDBDBD;
  }
  .collapse-text {
    .is-flex {
      justify-content: space-between;
      margin: 5px 0;
      max-width: 250px;
    }
    .button {
      padding: 5px 10px;
      height: 2.2em;
      min-width: 120px;
      &.white {
        background: white;
        border: 1px solid #A01B5B;
        color: #A01B5B;
      }
    }
  }
}
</style>