<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <h3 class="title carousel-item-title mb-6">Учетные данные и пароль</h3>

      <div class="mb-7">
        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="ВАШ E-mail *"
              v-model="formData.account.email.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.account.email) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="ИМЯ ПОЛЬЗОВАТЕЛЯ (ЛОГИН) *"
              v-model="formData.account.username.value"
              :disabled="status"
            />
            <p class="has-text-danger">
              {{ getErrorMessage(formData.account.username) }}
            </p>
          </div>
        </div>

        <hr />

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="ТЕКУЩИЙ ПАРОЛЬ"
              v-model="formData.account.current_password.value"
              :type="formData.account.current_password.fieldType"
              :disabled="status"
            >
            <template #after-input>
              <eye-icon @click.native="viewPassword('current_password')"/>
            </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.account.current_password) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-notice
              class="tnd-notice-custom"
              text="Заполните эти поля, чтобы изменить пароль"
              :disabled="status"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column pb-0">
            <tnd-input
              inputLabel="НОВЫЙ ПАРОЛЬ"
              v-model="formData.account.new_password.value"
              :type="formData.account.new_password.fieldType"
              :disabled="status"
            >
            <template #after-input>
              <eye-icon @click.native="viewPassword('new_password')"/>
            </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.account.new_password) }}
            </p>
          </div>
          <div class="column pb-0">
            <tnd-input
              inputLabel="ПОВТОРИТЕ НОВЫЙ ПАРОЛЬ"
              v-model="formData.account.password_repeat.value"
              :type="formData.account.password_repeat.fieldType"
              :disabled="status"
            >
            <template #after-input>
              <eye-icon @click.native="viewPassword('password_repeat')"/>
            </template>
            </tnd-input>
            <p class="has-text-danger">
              {{ getErrorMessage(formData.account.password_repeat) }}
            </p>
          </div>
        </div>

        <hr class="mb-5-5" />

        <p class="title is-size-5-5">РОЛИ: Исполнитель - основной</p>
      </div>
      <div class="has-text-centered mb-2">
        <button
          @click="save('account')"
          class="button is-normal main-button purple"
          :disabled="status"
        >
          <span>СОХРАНИТЬ</span>
        </button>
      </div>
    </section>
  </b-carousel-item>
</template>


<script>
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import validate from '../services/validate.service.js'
import EyeIcon from "@/components/icons/EyeIcon.vue";

const disabledStatus = 18

export default {
  props: ["getErrorMessage", "save"],
  components: {
    TndInput,
    TndNotice,
    EyeIcon,
  },
  name: "ProfileAccmount",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
    status() {
      return this.$store.getters['profile/orgStatus'] === disabledStatus
    }
  },
  methods: {
    viewPassword(field) {
      this.$store.commit('profile/changePasswordView', field)
    }
  },
  created() {
    for (let i in this.formData.account) {
      this.$watch(
        () => this.formData.account[i].value,
        () => {
          validate(this.formData.account[i])
        }
      )
    }
  },
};
</script>


<style scoped>
/* from tablet */
@media screen and (min-width: 768px) {
  .tnd-notice-custom {
    margin-top: 2.2em;
    width: 100%;
  }
}
</style>

