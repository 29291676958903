<template>
  <b-carousel-item>
    <section class="p-6 shadow-d">
      <div v-if="formData.categories">
        <h3 class="title carousel-item-title mb-6">Категории и виды работ</h3>

        <div class="mb-7">
          <div class="columns categories-columns">
            <div class="column p-0">
              <p>Выберите категорию</p>
              <div class="categories-list mt-3">
                <a 
                  v-for="(category, index) in formData.categories" 
                  :key="index" 
                  class="category-item" 
                  :class="{'active-category': index == activeCategory}"
                  @click="setActiveCategory(index)">
                  <span>{{category.name}}</span>
                  <b>{{category.active_children}}</b>                
                  <b-tooltip
                    v-if="category.attention_children"
                    type="is-light"
                    position="is-right"
                    class="category-tooltip"
                    multilined
                  >
                    <template v-slot:content>
                      Какие-то пункты этой категории не были аккредитованы. <br>Вы можете оспорить решение комиссии в конкретных пунктах
                    </template>
                    <img src="../assets/svg/tooltip-icon.svg" alt="">
                  </b-tooltip>
                </a>
              </div>
            </div>
            <div class="column p-0">
              <p>Выберите вид работ</p>
              <div class="workkinds-list mt-3" v-if="formData.categories[activeCategory]">
                <a 
                  v-for="(workKind, index) in formData.categories[activeCategory].children" 
                  :key="index" 
                  class="workkind-item"
                  @click="toggleActiveWorkKind(workKind)"
                  :class="{
                    'workkind-inactive': workKind.status == 2 || workKind.status == 3 || workKind.status == 5, 
                    'workkind-moderation': workKind.status == 2,
                    'workkind-to-moderation': workKind.status == 4}">
                  <span>{{workKind.name}}</span>
                  <b-tooltip
                    v-if="workKind.status == 2"
                    type="is-light"
                    class="workkind-tooltip"
                    multilined
                    :auto-close="['outside', 'escape']"
                  >
                    <template v-slot:content>
                      Этот пункт не был аккредитован
                      <div class="is-flex">
                        <button
                          class="button is-normal main-button white"
                          @click="challengeCategory(workKind.id)"
                        >
                          <span>Оспорить</span>
                        </button>
                        <button
                          @click="acceptCategoryDisapproval(workKind)"
                          class="button is-normal main-button purple"
                        >
                          <span>Принять</span>
                        </button>
                      </div>
                    </template>
                    <img src="../assets/svg/tooltip-icon.svg" alt="">
                  </b-tooltip>
                  <div class="workind-checkbox" v-else-if="!(activeCategory == 1 && index == 4)">
                    <box-checked v-if="(workKind.status == 1 && workKind.is_verified == 1) || workKind.status == 4" />
                    <box-unchecked v-else />
                  </div>
                  <b-tooltip
                    v-if="workKind.status == 3"
                    type="is-light"
                    class="workkind-tooltip"
                    multilined
                  >
                    <template v-slot:content>
                      Вы отправили запрос на оспаривание решения комиссии. Этот пункт находится на проверке
                    </template>
                    <img src="../assets/svg/category-refresh-icon.svg" alt="">
                  </b-tooltip> 
                  <b-tooltip
                    v-if="workKind.status == 4"
                    type="is-light"
                    class="workkind-tooltip"
                    multilined
                  >
                    <template v-slot:content>
                      Этот пункт находится на проверке
                    </template>
                    <img src="../assets/svg/category-refresh-icon.svg" alt="">
                  </b-tooltip>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="has-text-centered mb-2">
          <button
            @click="save('categories')"
            class="button is-normal main-button purple"
          >
            <span>СОХРАНИТЬ</span>
          </button>
        </div>
      </div>
      <div v-else><p>Чтобы указать категории и виды работ необходимо заполнить информацию во вкладке "Данные контрагента"</p></div>
    </section>
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <div>
        <div class="modal-card model-card__category-challange">
          <header class="modal-card-head">
            <div>
              <p class="modal-card-title">Оспаривание решения комиссии</p>
              <p>Опишите, почему вы не согласны с решением комиссии. По возможности Оставьте ссылки на подтверждающую информацию</p>
            </div>
            <button
              type="button"
              class="delete"
              @click="isComponentModalActive = false"/>
          </header>
          <section class="modal-card-body">
            <b-field>
              <b-input maxlength="200" type="textarea" v-model="challengeComment"></b-input>
            </b-field>
            <div class="has-text-centered mt-2">
              <button
                class="button is-normal main-button purple"
                @click="sendChallengeWorkTypeRequest"
              >
                <span>Отправить</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </b-modal>
  </b-carousel-item>
</template>


<script>

/*
const STATUS_NONE = 0; //пользователь не подавал на данный вид работ
const STATUS_VERIFIED = 1;
const STATUS_DECLINED = 2;
const STATUS_IS_DEBATING = 3;//на оспаривании
const STATUS_IS_REQUESTED = 4;//пользователь отправил первичный запрос
const STATUS_DECLINED_AND_ACCEPTED_BY_USER = 5;//админ отклонил и пользователь согласился с решением
*/
import TndInput from "@/components/common/TndInput.vue";
import TndNotice from "@/components/common/TndNotice.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import { urls } from '@/config'
import httpClient from '@/services/http.service'

export default {
  props: ["getErrorMessage", "save"],
  components: {
    TndInput,
    TndNotice,
    BoxChecked,
    BoxUnchecked,
  },
  data: () => ({
    workTypeToChallenge: 0,
    activeCategory: 0,
    isComponentModalActive: false,
    challengeComment: ''
  }),
  name: "ProfileCategories",
  computed: {
    formData() {
      return this.$store.getters["profile/profileData"];
    },
  },
  methods: {
    setActiveCategory(categoryId) {
      this.activeCategory = categoryId
    },
    toggleActiveWorkKind(workKind) {
      if (workKind.status == 1 || workKind.status == 0)
        workKind.status = 4
      else if (workKind.status == 4)
        workKind.status = 1
    },
    challengeCategory(id) {
      this.isComponentModalActive = true
      this.workTypeToChallenge = id
    },
    acceptCategoryDisapproval(workind) {
      workind.status = 5
      this.save('categories')
    },
    sendChallengeWorkTypeRequest() {
      httpClient
        .post(urls.apiUSerProfileWorkTypeUrl + '/' + this.workTypeToChallenge + '/challenge', {'comment': this.challengeComment})
        .then(({ data }) => {
          if (!data) throw "Couldn't get user search information";            
          if (data.success == true) {
            this.isComponentModalActive = false
            this.$buefy.toast.open({
              message: 'Успешно',
              type: "is-success",
              duration: 4000
            });
            this.$store.commit('profile/updateCategories', data.categories)
          } else 
            this.errors = data.errors
        })
        .catch((error) => {
          console.info("User Profile Loading Error: ", error);
          this.errors = error
        });
    }
  }
};
</script>

<style lang="scss">
.tooltip-content {
  background: white !important;
}
.model-card__category-challange {
  textarea {
    border: 1px solid #E4E4E4;
  }
}
.workkinds-list .b-tooltip .tooltip-trigger {
  display: flex;
}
</style>

<style scoped lang="scss">
.model-card__category-challange {
  max-width: 450px;
  .modal-card-head {
    align-items: flex-start;
    .modal-card-title + p {
      color: #6F6F6F;
      margin-top: 20px;
    }
  }
}
.workkind-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17.5px 15px;
  position: relative;
  border: 1px solid #E4E4E4;
  color: #323232 !important;
  .workind-checkbox {
    position: absolute;
    right: 15px;
    top: 16px;
  }
  &.workkind-inactive {
    color: #E4E4E4 !important;
    justify-content: space-between;
    align-items: center;
  }
  &.workkind-moderation {
    justify-content: space-between;
    align-items: center;
  }
  &.workkind-to-moderation {
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
  }
  .workkind-tooltip {
    .is-flex {
      justify-content: space-between;
      margin: 5px 0;
    }
    .button {
      padding: 0 10px;
      height: 2em;
      &.white {
        background: white;
        border: 1px solid #A01B5B;
        color: #A01B5B;
      }
    }
  }
}
.category-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  position: relative;
  border: 1px solid #E4E4E4;
  color: #323232 !important;
  .category-tooltip {
    padding-top: 4px;
    padding-left: 7px;
  }
  b {
    font-size: 12px;
    padding: 4px;
    background: #E4E4E4;
    border-radius: 3px;
    min-width: 24px;
    text-align: center;
    margin-left: 10px;
  }
  &:before {
    content: '';
    width: 18px;
    height: 16px;
    background: url('../assets/svg/folder-icon.svg');
    display: inline-block;
    margin-right: 10px;
  }
  &:after {
    content: '';
    width: 18px;
    height: 18px;
    background: url('../assets/svg/category-right-arrow.svg');
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 18px;
  }
  &.active-category {
    background: linear-gradient(94.43deg, #A2165B 0%, #5A004B 100%);
    color: white !important;
    &:before {
      background: url('../assets/svg/folder-icon-open.svg');
    }
    &:after {
      filter: brightness(0) invert(1);
    }
    b {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>

