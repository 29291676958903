<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.69206 4.49996L6.35872 1.16663L3.02539 4.49996" stroke="#A01B5B" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.35742 1.16663V9.16663" stroke="#A01B5B" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.5 9.1438V12.1438H12.5V9.1438" stroke="#A01B5B" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>


<script>
export default {
  name: 'FileProfileIcon',
  props: {
    color: {
      type: String,
      default: '#6F6F6F'
    }
  }
}
</script>

<style>

</style>