<template>
  <div class="tnd-file" v-if="file.name">
    <div class="file-info">
      <p class="file-info__name">{{ file.name }}</p>
      <p class="file-info__date">Дата загрузки: {{ file.dt_create || dt_create }}</p>
      <p class="file-info__size">Размер: {{ fileSizeMB(file) }} Mb</p>
    </div>
    <div class="file-actions">
      <slot name="actions">
        <button
          class="file-actions__button"
          @click="$emit('action', file)"
          v-if="action === 'delete' && !disabled"
        >
          <span>Удалить</span><trash-icon />
        </button>
        <a
          class="file-actions__button"
          :href="downloadFile(file)"
          download
        >
          <span>Скачать</span>
          <download-icon />
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "../icons/DownloadIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import httpClient from "@/services/http.service";
import { urls } from '@/config'

export default {
  components: { TrashIcon, DownloadIcon },
  name: "TndProfileFile",
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dt_create() {
      return this.$moment(this.file.dt_create).format("DD MMMM YYYY, HH:mm");
    },
  },
  methods: {
    fileSizeMB(file) {
      const fileSize = file.size || file.file_size
      return Math.round(fileSize/1024/1024 * 100) / 100
    },
    downloadFile(file) {
      return '/' + urls.apiFileGet+'/'+file.id
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";
.tnd-file {
  position: relative;
  background: #ffffff;
  box-shadow: 3px 0.3em 1.25em rgba(175, 175, 175, 0.15);
  border-radius: 0.3em;
  padding: 24px 34px 24px 2.5em;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 26px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(94.43deg, #a2165b 0%, #5a004b 100%);
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .file-info {
    &__name {
      font-style: normal;
      font-weight: 400;
      font-size: 1.25em;
      line-height: 23px;
      color: #323232;
      margin-bottom: 0.3em;
    }

    &__date,
    &__size {
      font-style: normal;
      font-weight: 350;
      font-size: 12px;
      line-height: 14px;
      color: $purple-bright;
    }
  }

  .file-actions {
    &__button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1em;
        color: #6f6f6f;
        margin-right: 7px;
        background: none;
      }
    }
  }
}
</style>
