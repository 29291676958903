<template>
  <section>

    <b-modal
      v-model="isEditDelegationModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Делегирование</p>
            <button
              type="button"
              class="delete"
              @click="isEditDelegationModal = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="E-mail или логин пользователя">
              <b-autocomplete
                v-model="userInfo.username"
                :data="filteredDataArray"
                clearable
                field="username"
                @select="option => selected = option">
                <template #empty>Не найдено</template>
              </b-autocomplete>
            </b-field>

            <tnd-input
              inputLabel="тип делегирования"
              v-model="userInfo.delegation_type"
              inputType="select"
              :options="[
                { value: '1', name: 'Постоянное' },
                { value: '2', name: 'Временное' },
              ]"
            />
            <tnd-input v-if="userInfo.delegation_type == '2'"
              inputLabel="Дата начала"
              inputType="datepicker"
              datepickerType="date"
              datepickerFormat="YYYY-MM-DD"
              v-model="userInfo.date_from"
            />
            <tnd-input v-if="userInfo.delegation_type == '2'"
              inputLabel="Дата окончания"
              inputType="datepicker"
              datepickerType="date"
              datepickerFormat="YYYY-MM-DD"
              v-model="userInfo.date_to"
            />
            <div 
              class="is-flex is-align-items-center cursor-pointer tnd-input-container"
              @click="toggleActivity">
              <div class="mr-1 pt-1">
                <BoxChecked v-if="userInfo.is_active" />
                <BoxUnchecked v-else />
              </div>
              <div class="tnd-input-label">
                активировать делегирование
              </div>
            </div>
            <div v-if="errors" class="errors is-size-7-2">
              <p
                v-for="(error, field) in errors"
                :key="field"
                class="mb-3 has-text-danger"
              >
                {{ error[0] }}
              </p>
            </div>
            <div class="has-text-centered mt-2">
              <button
                class="button is-normal main-button purple"
                @click="updateDelegation()"
              >
                <span>Отправить</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>

import TndInput from "@/components/common/TndInput.vue";
import BoxChecked from "@/components/icons/BoxChecked.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import { urls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  components: {
    TndInput,
    BoxChecked,
    BoxUnchecked,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
    isEditDelegationModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      selected: null,
      errors: []
    }
  },
  computed: {
      filteredDataArray() {
        return this.$store.getters['profile/userSearchData'].filter(option => {
          const username = this.userInfo.username || ''
          return (
            option.username
              .toString()
              .toLowerCase()
              .indexOf(username.toLowerCase()) >= 0
          )
        })
      }
  },
  methods: {
    updateDelegation() {
      this.errors = []
      const dataToSend = {
        type_id: parseInt(this.userInfo.delegation_type),
        delegate_user_id: (this.selected) ? this.selected.id : this.userInfo.user_id,
        active: this.userInfo.is_active ? 1 : 0,
        date_from: this.userInfo.date_from,
        date_to: this.userInfo.date_to,
      }
      if (this.userInfo.delegation_type && (this.selected || this.userInfo.user_id)) {
        httpClient
          .put(urls.apiUSerProfileDelegationUrl+'/'+this.userInfo.id, dataToSend)
          .then(({ data }) => {
            if (!data) throw "Couldn't get user search information";            
            if (data.success == true) {
              this.$buefy.toast.open({
                message: 'Успешно',
                type: "is-success",
                duration: 4000
              });
              this.$emit('closeEditModal')
              this.$store.commit('profile/updateDelegations', data.delegations)
            } else 
              this.errors = data.errors
          })
          .catch((error) => {
            console.info("User Profile Loading Error: ", error);
            this.errors = error
          });
      }
    },
    toggleActivity() {
      this.userInfo.is_active = !this.userInfo.is_active
    }
  }
}
</script>