<template>
  <div class="tnd-file-upload">
    <div class="tnd-file-upload__uploaded-files">
      <tnd-profile-file
        v-for="(file, index) of filesList"
        :file="file"
        action="delete"
        @action="deleteFile"
        :key="index"
        :disabled="disabled"
      />
    </div>

    <label
      class="tnd-file-upload__upload-container"
      v-bind:class="[
        disabled ? 'tnd-file-upload__upload-container-disabled' : '',
      ]"
      @dragover.prevent
      @dragleave="dragleave"
      @drop.prevent="uploadFile"
      v-if="multiple || (!multiple && !filesList.length)"
    >
      <input v-if="!disabled" type="file" ref="file" @change="uploadFile" :multiple="multiple" />
      <div class="upload-icon">
        <file-profile-icon />
      </div>
      <div class="upload-text">Загрузить новый документ</div>
    </label>
  </div>
</template>

<script>
import FileProfileIcon from "../icons/FileProfileIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import httpClient from "@/services/http.service";
import TndProfileFile from "./TndProfileFile.vue";

export default {
  components: {
    FileProfileIcon,
    TrashIcon,
    TndProfileFile,
  },
  name: "TndProfileUploadFile",
  props: {
    multiple: Boolean,
    files: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
  },
  data: () => ({
    list: [],
    kilobyte: 1024,
  }),
  computed: {
    filesList() {
      return this.$store.getters["profile/filesListById"](this.name);
    },
  },
  methods: {
    dragover() {},
    dragleave() {},
    uploadFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      const filesList = this.multiple ? Object.values(files) : [files[0]];

      this.$store.commit('profile/addNewFile', {files: filesList, name: this.name});
    },
    deleteFile(file) {
      this.$store.commit('profile/deleteFile', {file: file, name: this.name});
    },
  },
  watch: {
    filesList() {
    //   this.$store.commit("tenderRequest/setOrderFiles", {
    //     name: this.name,
    //     files: this.filesList[0],
    //   });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";
.tnd-file-upload {
  &__upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 2.25em;
    border: 1px dashed #c9c9c9;
    box-sizing: border-box;

    input[type="file"] {
      display: none;
    }

    .upload-icon {
      width: 1em;
      height: 1.25em;
      margin-right: .5em;
    }

    .upload-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1em;
      color: #6f6f6f;
    }

    &-disabled {
      background: #e5e5e5;

      .upload-text {
        color: #a5a3a3 !important
      }
    }
  }
}
</style>